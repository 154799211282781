import "../scss/plugins/_swiper.scss";
import Swiper from "swiper";
import { Navigation, Pagination, Thumbs } from "swiper/modules";

const defaultParameters = (slider) => {
	const paginationSelector = "[data-swiper-pagination]";
	const prevSelector = "[data-swiper-button-prev]";
	const nextSelector = "[data-swiper-button-next]";
	const pagination = slider.querySelector(paginationSelector);
	const prev = slider.querySelector(prevSelector);
	const next = slider.querySelector(nextSelector);
	return {
		modules: [Navigation, Pagination, Thumbs],
		spaceBetween: 20,
		pagination: pagination
			? {
					el: paginationSelector,
					type: "fraction",
			  }
			: false,
		navigation: {
			nextEl: next ? nextSelector : false,
			prevEl: prev ? prevSelector : false,
		},
	};
};

const sliders = document.querySelectorAll("[data-swiper]");
if (sliders.length) {
	sliders.forEach((slider) => {
		let customParameters = slider.dataset.swiper;
		customParameters = customParameters ? JSON.parse(customParameters) : {};

		if (customParameters.thumbs === true) {
			const thumbsSlider = slider.nextElementSibling;
			if (thumbsSlider && thumbsSlider.dataset.swiperThumbs !== undefined) {
				const thumbsParams = JSON.parse(thumbsSlider.dataset.swiper);
				customParameters.thumbs = { swiper: new Swiper(thumbsSlider, thumbsParams) };
			}
		}

		const parameters = { ...defaultParameters(slider), ...customParameters };
		new Swiper(slider, parameters);
	});
}
